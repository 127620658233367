import './Stats.css';
import React from 'react';

function Stats({ matchData }) {
    const queryParameters = new URLSearchParams(window.location.search);

    const alwaysShowStats = queryParameters.get('alwaysShowStats')?.toLowerCase() === 'true';
    const statsEnabled = matchData?.VolatileData?.ShowStats ?? false;
    const advancedStatsEnabled = alwaysShowStats || (matchData?.VolatileData?.ShowAdvancedStats ?? false);
    const statsAvailable = matchData?.Data?.Yellow?.Stats != null && matchData?.Data?.Black?.Stats != null;
    var statsIndex = matchData?.VolatileData?.StatsIndex ?? 0;

    const showStats = (alwaysShowStats || statsEnabled) && statsAvailable;

    if (!showStats) {
        return (
            <div></div>
        );
    }

    if (statsIndex > matchData.Data.Yellow.GameStats.length) {
        statsIndex = 0;
    }

    const yellowStats = statsIndex === 0 ? matchData.Data.Yellow.Stats : matchData.Data.Yellow.GameStats[statsIndex - 1];
    const blackStats = statsIndex === 0 ? matchData.Data.Black.Stats : matchData.Data.Black.GameStats[statsIndex - 1];

    return (
        <div className='Stats'>
            <PercentagesColumn stats={yellowStats} columnClass='LeftTeamPercentage' />
            {advancedStatsEnabled && <SummaryColumn leftTeamStats={yellowStats} rightTeamStats={blackStats} />}
            <PercentagesColumn stats={blackStats} columnClass="RightTeamPercentage" />
        </div>
    );
}

function PercentagesColumn({ stats, columnClass }) {
    return (
        <div className={columnClass}>
            <PercentagesRow stats={stats} title={'Passing'} value={'X5Y'} total={'X5T'} percentage={'X5PERCENTAGE'} />
            <PercentagesRow stats={stats} title={'Shooting'} value={'X3Y'} total={'X3T'} percentage={'X3PERCENTAGE'} />
            <ClearingRow stats={stats} title={'Clearing'} value={'X2C'} total={'X2CT'} percentage={'X2CPERCENTAGE'} />
            <PercentagesRow stats={stats} title={'2-Bar Pass'} value={'X2P'} total={'X2PT'} percentage={'X2PPERCENTAGE'} />
        </div>
    );
}

function PercentagesRow({ stats, title, value, total, percentage }) {
    return (
        <div className='PercentagesRow'>
            <div className='PercentagesTitle'>{title}</div>
            <div className='PercentagesText'>
                <span className='PercentagesValue'>{stats[value]}</span> for <span className='PercentagesValue'>{stats[total]}</span> {(stats[total] !== 0) && <span className='PercentagesPercentValue'>{stats[percentage]}%</span>}
            </div>
        </div>
    );
}

function ClearingRow({ stats, title, value, total, percentage }) {
    return (
        <div className='PercentagesRow'>
            <div className='PercentagesTitle'>{title}</div>
            <div className='PercentagesText'>
                <div>
                    <span className='PercentagesValue'>{stats[value]}</span> for <span className='PercentagesValue'>{stats[total]}</span> {(stats[total] !== 0) && <span className='PercentagesPercentValue'>{stats[percentage]}%</span>}
                </div>
                <div>
                    On Goal: {stats['XSOG']}
                </div>
            </div>
        </div>
    );
}

function SummaryColumn({ leftTeamStats, rightTeamStats }) {
    return (
        <div className='SummaryColumn'>
            <SummaryRow title={'Scoring'} value={'XSCORE'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow SummaryRowHeader' />
            <div className='SummarySeparator'></div>
            <SummaryRow title={'3-Bar'} value={'X3Y'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow SummaryRowUnderHeader' />
            <SummaryRow title={'5-Bar'} value={'X5G'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow' />
            <SummaryRow title={'2-Bar'} value={'X2G'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow' />
            <SummaryRow title={'Breaks'} value={'XSL'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow' />
            <SummaryRow title={'Stuffs'} value={'XST'} leftTeamStats={leftTeamStats} rightTeamStats={rightTeamStats} rowClass='SummaryRow' />
        </div>
    );
}

function SummaryRow({ title, value, leftTeamStats, rightTeamStats, rowClass }) {
    return (
        <div className={rowClass}>
            <span className='SummaryValue'>{leftTeamStats[`${value}`]}</span> <span className='SummaryTitle'>{title}</span> <span className='SummaryValue'>{rightTeamStats[`${value}`]}</span>
        </div>
    );
}

export default Stats;
