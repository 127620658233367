import './Event.css';
import React from 'react';

function Event({ matchData }) {
    return (
        <div className='Event'>
            {matchData?.Data?.Event?.Name ?? ''}
        </div>
    );
}

export default Event;
