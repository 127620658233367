import './Odds.css';
import '../../styles/TeamColors.css'
import { useSpring, animated } from 'react-spring';

function Odds({ matchData }) {
    const queryParameters = new URLSearchParams(window.location.search);
    const swapColors = queryParameters.get('swapColors')?.toLowerCase() === 'true';

    const previousLeftTeamOdds = (swapColors ? matchData?.Previous?.Data?.Odds?.Black : matchData?.Previous?.Data?.Odds?.Yellow) ?? 50;
    const currentLeftTeamOdds = (swapColors ? matchData?.Data?.Odds?.Black : matchData?.Data?.Odds?.Yellow) ?? 50;
    const previousRightTeamOdds = 100 - previousLeftTeamOdds;
    const currentRightTeamOdds = 100 - currentLeftTeamOdds;

    const previousPosition = calculateHeaderPosition(previousLeftTeamOdds);
    const position = calculateHeaderPosition(currentLeftTeamOdds);

    const moveSlider = useSpring({
        from: { left: `${previousPosition}%` },
        to: { left: `${position}%` }
    });

    const moveLeftTeam = useSpring({
        from: { width: `${previousLeftTeamOdds}%` },
        to: { width: `${currentLeftTeamOdds}%` }
    });

    const moveRightTeam = useSpring({
        from: { width: `${previousRightTeamOdds}%` },
        to: { width: `${currentRightTeamOdds}%` }
    });

    if (!matchData?.Data?.Odds) {
        return (
            <div></div>
        );
    }

    return (
        <div className='Odds'>
            <div className='OddsLine'>
                <animated.div className={swapColors ? 'BlackTeamColors' : 'YellowTeamColors'} style={moveLeftTeam}></animated.div>
                <animated.div className={swapColors ? 'YellowTeamColors' : 'BlackTeamColors'} style={moveRightTeam}></animated.div>
            </div>

            <animated.div className='OddsHeader' style={moveSlider}>
                <div className={'OddsHeaderLeftTeam ' + (swapColors ? 'BlackTeamColors' : 'YellowTeamColors')}>{currentLeftTeamOdds}</div>
                <div className='OddsHeaderCenter'>Odds</div>
                <div className={'OddsHeaderRightTeam ' + (swapColors ? 'YellowTeamColors' : 'BlackTeamColors')}>{currentRightTeamOdds}</div>
            </animated.div>
        </div>
    );
}

function calculateHeaderPosition(leftTeamOdds) {
    // The odds slider takes up 9.7% of horizontal space.
    return clamp(leftTeamOdds - 4.85, 0, 100 - 9.7);
}

function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

export default Odds;
