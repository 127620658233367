import './Timer.css';
import React, { useState, useEffect } from 'react';

function Timer({ expiration }) {
    const [countdown, setCountdown] = useState(
        expiration - Date.now()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(expiration - Date.now());
        }, 25);

        return () => clearInterval(interval);
    }, [expiration]);

    const seconds = countdown > 0 ? Math.floor((countdown % (1000 * 60)) / 1000) : 0;
    const tenths = countdown > 0 ? Math.floor((countdown % 1000) / 100) : 0;

    return (
        <div className='Timer'>
            <div className='TimerSeconds'>{seconds}</div>
            <div className='TimerSeparator'>.</div>
            <div className='TimerTenths'>{tenths}</div>
        </div>
    );
}

export default Timer;
