import './Scoreboard.css';
import Bracket from '../Bracket/Bracket';
import Event from '../Event/Event'
import Format from '../Format/Format';
import React from 'react';
import Teams from '../Teams/Teams';
import { useSpring, animated } from '@react-spring/web'

function Scoreboard({ matchData }) {
    const scoreboardStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: matchData?.Data ? 1 : 0 }
    });

    return (
        <animated.div className='Scoreboard' style={scoreboardStyle}>
            <div className='TriangleLeftDarker'>
                <svg width="200" height="117" viewBox="100 0 100 100">
                    <polygon strokeLinejoin="round" points="0,0 200,240 200,-240" />
                </svg>
            </div>
            <div className='TriangleLeftDark'>
                <svg width="200" height="117" viewBox="0 -50 100 100">
                    <polygon strokeLinejoin="round" points="0,0 200,240 200,-240" />
                </svg>
            </div>
            <div className='TriangleLeftLight'>
                <svg width="200" height="117" viewBox="0 -50 100 100">
                    <polygon strokeLinejoin="round" points="0,0 200,240 200,-240" />
                </svg>
            </div>
            <div className='TriangleRightDarker'>
                <svg width="370" height="117" viewBox="-40 125 100 100">
                    <polygon strokeLinejoin="round" points="0,0 200,200 0,200" />
                </svg>
            </div>
            <div className='TriangleRightDark'>
                <svg width="200" height="117" viewBox="-100 -50 100 100">
                    <polygon strokeLinejoin="round" points="0,0 -200,240 -200,-240" />
                </svg>
            </div>
            <div className='TriangleRightLight'>
                <svg width="200" height="117" viewBox="-100 -50 100 100">
                    <polygon strokeLinejoin="round" points="0,0 -200,240 -200,-240" />
                </svg>
            </div>
            {matchData.Data && <Event matchData={matchData} />}
            {matchData.Data && <Teams matchData={matchData} />}
            {matchData.Data && <Bracket matchData={matchData} />}
            {matchData.Data && <Format matchData={matchData} />}
        </animated.div>
    );
}

export default Scoreboard;
