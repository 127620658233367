import './App.css';
import './font/static/Rubik-Regular.ttf'
import React, { useState, useEffect } from 'react';
import Scoreboard from './components/Scoreboard/Scoreboard';
import Logo from './components/Logo/Logo';
import Odds from './components/Odds/Odds';
import Stats from './components/Stats/Stats';
import TableNumber from './components/TableNumber/TableNumber';

function App() {
  const [matchData, setMatchData] = useState({});

  const queryParameters = new URLSearchParams(window.location.search);
  const organization = queryParameters.get('organization');
  const tournament = queryParameters.get('tournament');
  const station = queryParameters.get('station');
  const showOdds = queryParameters.get('showOdds')?.toLowerCase() === 'true';

  useEffect(() => {
    const interval = setInterval(() => {
      if (organization && tournament && station) {
        fetch(`https://modern-foosball-api.azurewebsites.net/api/organizations/${organization}/tournaments/${tournament}/stations/${station}/CurrentMatch`)
          .then(response => response ? response.json() : {})
          .then(data => setMatchData((previous) => { if (previous) { delete previous.previous; }; data.Previous = previous; return data; }))
          .catch(function onError(error) {
            setMatchData({});
          });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [organization, station, tournament]);

  return (
    <div className="App">
      <Logo matchData={matchData} />
      <TableNumber matchData={matchData} />
      <Stats matchData={matchData} />
      <Scoreboard matchData={matchData} />
      {showOdds && <Odds matchData={matchData} />}
    </div>
  );
}

export default App;
