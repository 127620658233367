import './Teams.css';
import React from 'react';
import Score from '../Score/Score';

function Teams({ matchData }) {
    const queryParameters = new URLSearchParams(window.location.search);
    const swapColors = queryParameters.get('swapColors')?.toLowerCase() === 'true';

    return (
        <div className='Teams'>
            <LeftTeam matchData={matchData} swapColors={swapColors} />
            <Score matchData={matchData} />
            <RightTeam matchData={matchData} swapColors={swapColors} />
        </div>
    );
}

function LeftTeam({ matchData, swapColors }) {
    const team = swapColors ? matchData?.Data?.Black : matchData?.Data?.Yellow;

    return (
        <div className='LeftTeam'>
            <div className={swapColors ? 'BlackTriangle' : 'YellowTriangle'}>
                <svg width="117" height="117" viewBox="-30 170 100 100">
                    <polygon strokeLinejoin="round" points="0,220 200,-35 200,475" />
                    {((matchData?.Data?.Winner === 'Yellow' && !swapColors) || (matchData?.Data?.Winner === 'Black' && swapColors)) && <polygon className='Star' strokeLinejoin="round" points="-11,210 -17,228 -2,217 -20,217 -5,228" />}
                </svg>
            </div>
            <div className={'LeftTeamContent ' + (swapColors ? 'BlackTeamColors' : 'YellowTeamColors')}>
                <div className='LeftTeamNames'>
                    {(team?.Players ?? []).length > 0 && <div className='PlayerName'>{formatPlayerName(team.Players[0].FullName)}</div>}
                    {(team?.Players ?? []).length > 1 && <div className='PlayerName'>{formatPlayerName(team.Players[1].FullName)}</div>}
                </div>
            </div>
        </div>
    );
}

function RightTeam({ matchData, swapColors }) {
    const team = swapColors ? matchData?.Data?.Yellow : matchData?.Data?.Black;

    return (
        <div className='RightTeam'>
            <div className={'RightTeamContent ' + (swapColors ? 'YellowTeamColors' : 'BlackTeamColors')}>
                <div className='RightTeamNames'>
                    {(team?.Players ?? []).length > 0 && <div className='PlayerName'>{formatPlayerName(team.Players[0].FullName)}</div>}
                    {(team?.Players ?? []).length > 1 && <div className='PlayerName'>{formatPlayerName(team.Players[1].FullName)}</div>}
                </div>
            </div>
            <div className={swapColors ? 'YellowTriangle' : 'BlackTriangle'}>
                <svg width="117" height="117" viewBox="200 170 100 100">
                    <polygon strokeLinejoin="round" points="0,-50 200,220 0,490" />
                    {((matchData?.Data?.Winner === 'Black' && !swapColors) || (matchData?.Data?.Winner === 'Yellow' && swapColors)) && <polygon className='Star' strokeLinejoin="round" points="213,210 207,228 222,217 204,217 219,228" />}
                </svg>
            </div>
        </div>
    );
}

function formatPlayerName(name) {
    var formattedName = name ?? '';
    formattedName = formattedName.trim();
    return formattedName;
}

export default Teams;
