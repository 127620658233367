import './Logo.css';
import React from 'react';
import Image from './ModernFoos.png'
import { useSpring, animated } from '@react-spring/web'

function Logo({ matchData }) {
    const logoStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const queryParameters = new URLSearchParams(window.location.search);
    const alwaysShowStats = queryParameters.get('alwaysShowStats')?.toLowerCase() === 'true';
    const statsEnabled = matchData?.VolatileData?.ShowStats;
    const statsAvailable = matchData?.Data?.Yellow?.Stats !== null && matchData?.Data?.Black?.Stats !== null;
    const statsIndex = matchData?.VolatileData?.StatsIndex;
    const showStats = (alwaysShowStats || statsEnabled) && statsAvailable;

    return (
        <animated.div style={logoStyle} className='Logo'>
            <LogoIcon />
            <LogoTitle showStats={showStats} statsIndex={statsIndex} />
        </animated.div>
    );
}

function LogoIcon() {
    return (
        <img className='LogoIcon' src={Image} alt='Modern Foos Icon' />
    );
}

function LogoTitle({ showStats, statsIndex }) {
    return (
        <div className='LogoTitle'>
            {showStats === true && statsIndex > 0 ? `GAME ${statsIndex}` : ''}
        </div>
    );
}

export default Logo;
