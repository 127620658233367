import './TableNumber.css';
import React from 'react';
import { useSpring, animated } from '@react-spring/web'

function TableNumber({ matchData }) {
    const tableNumberStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const queryParameters = new URLSearchParams(window.location.search);
    const tableNumber = queryParameters.get('station') ?? '1';
    const showTableNumber = queryParameters.get('showStationNumber')?.toLowerCase() === 'true';

    return (
        <animated.div style={tableNumberStyle} className='TableNumber'>
            {showTableNumber && tableNumber && tableNumber > 1 ? `TABLE ${tableNumber}` : ''}
        </animated.div>
    );
}

export default TableNumber;
